const EXPERIMENT_DEFAULTS = {
    name: '',
    language: 'es',
    maxParticipants: 50,
    externalCodeChoice: 'none',
    completionCode : '',
    consentText: '',
    askEmail: true,
    probabilisticPayment: {
        paidParticipants: [],
        paidGames: [],
    },
    openToParticipants: true,
};

export default EXPERIMENT_DEFAULTS;
/*
 Puntos de entrada a checkout experiment:
    - Desde newExperiment: experimento nuevo, con todos los parámetros por defecto, sin id
        -
    - Desde la experimentCard (edit): experimento existente, con los parametros que tuviera, con id
        -
    - Desde la experimentCard (fromExisiting): nuevo experimento a partir de otro, con los parametros del otro, pero sin id ni nombre
        -
*/
