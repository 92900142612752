import * as React from "react"
import GenericModal from "./GenericModal";

const InfoModal = (props) => {

    return (
        <GenericModal 
            maxWidth="xl"
            title={`Informacion: ${props.title}`}
            open={props.open}
            renderContent={<img style={{width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden',}} src={props.imgUrl} alt="Game description" />}
            onClose={props.onClose}
        />
    );
}

export default InfoModal;