import * as React from 'react';
import { graphql } from 'gatsby';
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Snackbar,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { navigate } from "gatsby";
import GameCard from "../components/GameCard"
import {SIDEBAR_COLLAPSED_WIDTH} from "../components/SideBar"
import BeGeneralPage from "../components/be-general-page";
import EXPERIMENT_DEFAULTS from "../utils/experimentDefaults"
import useLocalStorageState from 'use-local-storage-state'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { AuthContext, GamesContext } from '../contexts/contexts.js';

const ROLE_LEVEL = {
	'admin': 0,
	'researcher': 50,
};

const continueButtonStyles = {
	display: 'flex',
	fontSize:'22px', 
	backgroundColor:'#28A745', 
	height:'30px',
	position: 'fixed',
	borderRadius: '24px',
	bottom: '48px',
	right: '48px',
	width: '200px',
	padding: '16px',
}

// markup
const NewExperiment = (props) => {
    const {t} = useTranslation();

	const gamesInfo = React.useContext(GamesContext);
	const authContextValue = React.useContext(AuthContext);
	const [availableGames, setAvailableGames] = React.useState([]);
	const [experiment, setExperiment] = useLocalStorageState("experiment", null);
	const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});
	const [loading, setLoading] = React.useState(true);
	const [showGameAddedAlert, setShowGameAddedAlert] = React.useState(false);
	const [notUpdated, setNotUpdated] = React.useState(true);
	const firstUpdate = React.useRef(true);

	async function loadAvailableGamesForResearcher() {
		const gamesAvailableFromContext = gamesInfo?.filter((game) => authContextValue.userData.gamePermissions[game.id]?.visible && game.id !== "questions");

		setAvailableGames(gamesAvailableFromContext);
		if(!experiment) {
			setGamePosition(undefined);
			setExperiment({
				games: [],
				...EXPERIMENT_DEFAULTS,
			})
		}
		if (gamesAvailableFromContext?.length) {
			setLoading(false);
		}
	};
  
	React.useEffect(() => {
	  loadAvailableGamesForResearcher();
	}, [gamesInfo, authContextValue]);

	React.useLayoutEffect(() => { 
		if (firstUpdate.current && notUpdated) { 
			firstUpdate.current = false; 
			setNotUpdated(false);
			return; 
		} 

		if(experiment?.games.length > 0){
			setShowGameAddedAlert(true);
		}
		
		return () => (firstUpdate.current = true); 
	}, [experiment?.games.length]);

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<BeGeneralPage title={t("Choose the games for your experiment")}>
				{
					(authContextValue?.userData && !authContextValue.userData.isAdminValidated) ?
						<Alert severity="warning"><Trans>Your account hasn't been validated yet, you can select games, but you won't be able to create experiments until validation</Trans></Alert>
					:
						null
				}
				<Snackbar 
					open={showGameAddedAlert} 
					autoHideDuration={1000} 
					onClose={() => {setShowGameAddedAlert(false)}} 
					anchorOrigin={{vertical:'top', horizontal:'center'}}
				>
					<Alert
						onClose={() => {setShowGameAddedAlert(false)}}
						severity="success"
						variant="filled"
						sx={{ width: '100%' }}
					>
						<Trans>The game has been added to your experiment successfully</Trans>
					</Alert>
				</Snackbar>
				<Grid container spacing={2} sx={{ overflow: 'auto' }} padding={4} mh={SIDEBAR_COLLAPSED_WIDTH}>
					{availableGames?.map((elem, index) => {
						return (
							<Grid item xs={12} md={6} lg={4} key={`game-card-${index}`} pb={8}>
								<Box sx={{height: '250px'}}>
									<GameCard 
										game={elem} 
										disabled={!(elem.parametrizable || authContextValue.userData.gamePermissions[elem.id]?.usable)}
									/>
								</Box>
							</Grid>
						);
					})}
				</Grid>
				{experiment?.games?.length > 0 ?
					<Button 
						sx={continueButtonStyles}
						variant='contained' 
						disabled={experiment?.games?.length === 0}
						onClick={()=>{navigate('/checkoutExperiment/', props.location.state)}}
					>
						<p style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}><Trans>Continue</Trans><ArrowForwardIosIcon/></p>
					</Button>
				:
					null
				}
			</BeGeneralPage>
		</>
	);
};

export default NewExperiment;

export const graphQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games","game_card", "game_card_descriptions","new_experiment",]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
