import * as React from "react"
import { 
    Button,
    Grid,
    IconButton,
    Paper,
    Tooltip,
} from "@mui/material";

import InfoModal from "../containers/InfoModal";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import useLocalStorageState from 'use-local-storage-state'
import { useTranslation, Trans} from 'gatsby-plugin-react-i18next';
import { navigate } from "gatsby";


const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

const availableActions = [
    { 
        "action": "Preview this game",
        "icon": <VisibilityRoundedIcon />,
        "disabled": false,
        "callback": () => {console.log("Preview pressed")},
    },
    { 
        "action": "Add this game to the experiment",
        "icon": <AddCircleOutlineRoundedIcon/>,
        "disabled": false,
        "callback": () => {console.log("Add pressed")},
    },
];

const defaults = {
    title: "Juego de prueba",
    description: lorem,
    actions: availableActions,
}


const GameCard = (props) => {
    const {t} = useTranslation();
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [openInfo, setOpenInfo] = React.useState(false);
    const game = props.game || defaults;
    const disabled = props.disabled || false;

    const addedToExperiment = experiment?.games?.find(g => g.id === game.id);

    const addGameToExperiment = (game) => {
        if (game.parametrizable) {
            navigate(`/gameCreation/${game.parametrizable}/`);
        } else /*if (!checkIfArrayContainsProperty(experiment.games, 'id', game.id)) */{
            const gamesLikeThis = experiment.games.filter((g) => (game.id === g.id))
            const maxInstance = Math.max(gamesLikeThis.map((g) => g.instance))
            if(!maxInstance){
                game.instance = 1;
            }
            else{
                game.instance = maxInstance+1;
            }
            game["listId"] = `${game.id}_${game.instance}`;
            experiment.games.push(game);
            setExperiment(experiment);
        }
    }

    return (
        <>
        <InfoModal 
            title={t(game.title)}
            open={openInfo}
            imgUrl={game.instructionsImgUrl}
            onClose={()=>{setOpenInfo(false)}}
        />

        <Paper elevation={3} style={{ height: '100%', padding: '16px'}}>
            <Grid container>
                <Grid item xs={11} style={{justifyContent: 'top', backgroundColor: '#fff'}}>
                    <p style={{fontSize: '20px', }}>{t(game.title)}</p>
                </Grid>
                <Grid item xs ={1} style={{backgroundColor: '#fff'}}>
                    <Tooltip title={t('Open game info')}>
                        <IconButton onClick={()=>{setOpenInfo(true)}} size='large'>
                            <InfoOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sx={{ height: '140px', overflow: 'auto' }}>
                    <p style={{fontSize: '12px', color: '#8b8b8b', textAlign: 'justify'}}>{t(`${game.id}_card_description`)}</p>
                </Grid>
                <Grid item xs={12} textAlign={'center'} mt={'8px'}>
                    <Button
                        disabled={disabled}
                        variant={'contained'}
                        onClick={() => {addGameToExperiment(game)}}
                    >
                        { addedToExperiment ?
                            <Trans>Add Game again</Trans>
                            :
                            <Trans>Add Game to experiment</Trans>

                        }
                        
                    </Button>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'right'}}>
                    {(game?.actions?.map((elem) => {
                        return (
                            <Tooltip title={elem.action}>
                                <IconButton disabled={elem.disabled} onClick={elem.callback} size='large'>
                                    {elem.icon}
                                </IconButton>
                            </Tooltip>
                        );
                    }))}
                </Grid>
            </Grid>
        </Paper>
        </>
    );
}

export default GameCard